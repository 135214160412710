<template>
  <div class="article_list">
    <div class="head_section">
      <h2 id="dkBody" class="tit_section">진행중 문서함</h2>
    </div>
    <search-div
      title-search="제목"
      title-date="상신일시"
      :isSearchTypeRadio="false"
      @init-search-form="initSearchForm"
      @select-connector="selectConnector"
    >
      <!-- :open-search-box="false" -->
    </search-div>
    <list-div
      ref="docList"
      :open-doc-no="true"
      :open-draft-date="true"
      :open-approval-line="true"
      :doc-list="docList"
      :total-page="totalPage"
      :listLineDatas="listLineDatas"
      :doc-status="docStatus.PROCESSING"
      :doc-flag="'DI'"
      @go-detail="goDetail"
      @get-doc-list="getDocList"
    />
  </div>
</template>
<script>
import SearchDiv from "@/_approval/components/shared/list/SearchDiv";
import ListDiv from "@/_approval/components/shared/list/ListDiv";
import { docStatus } from "@/_approval/const/const";
import commDocs from "@/_approval/mixins/common/commDocs";
import ApiService from "@/services/ApiService";

export default {
  name: "DraftIngDocs",
  components: { ListDiv, SearchDiv },
  mixins: [commDocs],
  data() {
    return {
      searchForm: {},
      totalPage: 0,
      currentPage: 0,
      docList: [],
      docStatus: docStatus,

      listLineDatas: [
        { colStyle: "width: 56px", key: "checkbox" },
        { text: "문서번호", colStyle: "width: 180px", key: "docNo" },
        { text: "구분", colStyle: "width: 120px", key: "targetGubun" },

        {
          text: "제목",
          colStyle: "",
          key: "docTitle",
          class: "cell_left td_ellip",
        },
        {
          text: "기안요청자",
          colStyle: "width: 172px",
          key: "drftAccountId",
          key2: "drftEmpName",
        },
        {
          text: "결재대기자",
          colStyle: "width: 172px",
          key: "nextAprvlAccountId",
          key2: "nextAprvlEmpName",
        },
        { text: "상신일시", colStyle: "width: 106px", key: "drftDate" },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    // 상세화면으로 오는 게 아니면 폼 양식 데이터 삭제
    if (from.name !== "DraftIngDetail") {
      localStorage.removeItem("searchForm");
      localStorage.removeItem("pageNo");
    }
    next(true);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "DraftIngDetail") {
      localStorage.pageNo = this.currentPage;
    }
    next(true);
  },
  methods: {
    selectConnector(formData) {
      this.searchForm = formData;
      localStorage.searchForm = JSON.stringify(this.searchForm);
      this.$refs.docList.resetSort(1); // 서칭시 페이지 초기화로 reset
    },
    initSearchForm(formData) {
      this.searchForm = formData;
    },
    async getDocList(pagingConfig, sort) {
      const params = this.makeBaseDocListPaymentParams(pagingConfig, this.searchForm);
      params.docStatus = docStatus.PROCESSING;
      const res = await ApiService.shared.get(`${this.$apiPath.APRVL_DOC_LIST}`, {
        params: params,
      });
      if (!this.checkNormalResponse(res)) {
        return;
      }

      if (res.data && res.data.length > 0) {
        res.data.forEach((e) => (e.regDate = this.strDateTimeSplit(e.regDate)));
      }

      this.totalPage = parseInt(res.total);
      this.docList = res.data;
    },
    goDetail(docNo) {
      this.$router.push({ path: `${PAYMENT_VIEW_DETAIL}/${docNo}` });
    },
  },
};
</script>
